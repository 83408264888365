import React, { Fragment } from 'react'
import { graphql } from 'gatsby'

const Categories = ({ categories }) => (
  <Fragment>
    <ul>
      {categories.map(category => (
        <li key={category}>
          <a href={`/categories/${category}`}>{category}</a>
        </li>
      ))}
    </ul>
  </Fragment>
)

const Blog = ({
  data: { site, allMdx },
  pageContext: { pagination, categories },
}) => {
  const { page, nextPagePath, previousPagePath } = pagination

  const posts = page.map(id => allMdx.edges.find(edge => edge.node.id === id))

  return (
    <div>
      <div>
        All categories on the blog: <Categories categories={categories} />
      </div>

      {posts.map(({ node: post }) => (
        <div key={post.id}>
          <h2>
            <a href={post.frontmatter.slug}>{post.frontmatter.title}</a>
          </h2>

          <small>{post.frontmatter.date}</small>

          <p>{post.excerpt}</p>

          <a href={post.frontmatter.slug}>Continue Reading</a>
        </div>
      ))}

      <hr />

      <div>
        Pagination:
        <ul>
          {nextPagePath && (
            <li>
              <a href={nextPagePath}>Next Page</a>
            </li>
          )}

          {previousPagePath && (
            <li>
              <a href={previousPagePath}>Previous Page</a>
            </li>
          )}
        </ul>
      </div>
    </div>
  )
}

export default Blog

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx {
      edges {
        node {
          excerpt(pruneLength: 300)
          id
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            slug
            categories
            keywords
          }
        }
      }
    }
  }
`
